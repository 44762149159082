import axios from '@/configs/http/internal-api-axios'
import get from 'lodash-es/get'
import { extendMasterRead } from '@/utils/function-utils'

const baseUrl = 'api/v1/mt/apps'

async function getDataTable(perPage = 10, currentPage = 1, keySearch = '', isMaster = false) {
  let url = `${baseUrl}?perPage=${perPage}&currentPage=${currentPage}&keyword=${keySearch}`
  url = extendMasterRead(isMaster, url)
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return {
    items: response.data.items,
    total: response.data.total,
  }
}

function getFields() {
  return [
    {
      key: 'code',
      label: 'サービスID',
      sortable: true,
      tdClass: 'align-middle',
      thClass: 'text-left',
    },
    {
      key: 'logo',
      label: 'ロゴ',
      sortable: false,
      tdClass: 'text-left align-middle',
      thClass: 'text-left',
    },
    {
      key: 'name',
      label: 'サービス名',
      sortable: true,
      tdClass: 'text-left align-middle',
      thClass: 'text-left',
    },
    {
      key: 'category_name',
      label: 'カテゴリー',
      sortable: true,
      tdClass: 'text-left align-middle',
      thClass: 'text-left',
    },
    {
      key: 'total_companies',
      label: '利用中の顧客数',
      sortable: true,
      tdClass: 'text-left align-middle',
      thClass: 'text-left',
    },
    {
      key: 'total_users',
      label: '利用中の従業員数',
      sortable: true,
      tdClass: 'text-left align-middle',
      thClass: 'text-left',
    },
    {
      key: 'status',
      label: 'ステータス',
      sortable: true,
      tdClass: 'text-center align-middle',
      thClass: 'text-center',
    },
    { key: 'actions', label: '', tdClass: 'text-center align-middle', thClass: 'text-center' },
  ]
}

async function getDataDetail(id, isMaster = false) {
  let url = `${baseUrl}/${id}`
  url = extendMasterRead(isMaster, url)
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return response.data
}

async function getDataTableDetail(id, perPage = 10, currentPage = 1, keySearch = '') {
  const url = `${baseUrl}/${id}/connects?perPage=${perPage}&currentPage=${currentPage}&keyword=${keySearch}`
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return {
    items: response.data.items,
    total: response.data.total,
  }
}

async function deleteItem(id) {
  if (id !== '') {
    const url = `${baseUrl}/${id}`
    return axios.delete(url).then((res) => {
      return res.data.success
    })
  }
  return false
}

async function createItem(formData) {
  const response = await axios
    .post(baseUrl, formData)
    .then((response) => {
      return {
        success: true,
        data: response.data,
      }
    })
    .catch((error) => {
      return {
        success: false,
        data: error.response.data.errors,
      }
    })
  return {
    success: response.success,
    data: response.data,
  }
}

async function editItem(id, formData) {
  const url = `${baseUrl}/${id.id}`
  const response = await axios
    .patch(url, formData)
    .then((res) => {
      return {
        success: true,
        data: res.data,
      }
    })
    .catch((err) => {
      return {
        success: false,
        data: err.response.data.errors,
      }
    })
  return {
    success: response.success,
    data: response.data,
  }
}

async function getAppByCode(code) {
  const url = `${baseUrl}?keyword=${code}`
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  const apps = get(response, 'data.items', [])
  if (apps.length > 0) {
    return apps.filter((app) => app.code === code)
  }
  return []
}

async function getSaaSList(keySearch = '', categoryId = '') {
  const url = `api/v1/apps?keyword=${keySearch}&category_id=${categoryId}`
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return {
    items: response.data.items,
    total: response.data.total,
  }
}

export const saasManagementService = {
  getDataTable,
  getFields,
  deleteItem,
  createItem,
  editItem,
  getDataDetail,
  getDataTableDetail,
  getAppByCode,
  getSaaSList,
}
