<template>
  <div class="MSForm">
    <b-form>
      <!--   start choose image-->
      <b-form-group id="input-group-1" class="MSForm__image">
        <b-input-group>
          <img :src="form.logo || defaultLogo" alt="logo-image" @click="handleClickImage" />
          <label :ref="imageRef" for="input-image" class="MSForm__logo-label">
            <b-button class="MSForm__logo-button" variant="light">画像アップロード</b-button>
          </label>
          <input
            id="input-image"
            ref="upload-image"
            class="MSForm__logo-file"
            type="file"
            accept=".png,.jpg,.jpeg"
            @change="onFileChange"
          />
          <span v-if="!!errorMessages.form.logo" class="label-error"
            >{{ errorMessages.form.logo }}
          </span>
        </b-input-group>
      </b-form-group>
      <!--    end choose image-->

      <!--   start layout modal-->
      <b-form-group
        id="input-group-2"
        class="mb-3 required"
        label="サービス名"
        label-for="input-name"
      >
        <b-input-group>
          <b-form-input id="input-name" :value="form.name" type="text" disabled />
          <b-input-group-append v-if="!!errorMessages.form.name" class="icon-alert">
            <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
          </b-input-group-append>
        </b-input-group>
        <span v-if="!!errorMessages.form.name" class="label-error"
          >{{ errorMessages.form.name }}
        </span>
      </b-form-group>

      <b-form-group id="select-group-1" class="mb-3" label="カテゴリー" label-for="select-1">
        <b-form-select
          id="select-1"
          :value="form.category_id"
          class="form-select"
          :options="categoryOptions"
          :class="status($v.form.category_id)"
          type="text"
          @change="setFormValue('category_id', $event)"
        />
        <span v-if="!!errorMessages.form.category_id" class="label-error"
          >{{ errorMessages.form.category_id }}
        </span>
      </b-form-group>

      <b-form-group
        id="select-group-2"
        class="mb-3 required"
        label="ステータス"
        label-for="select-2"
      >
        <b-form-select
          id="select-2"
          :value="form.status"
          class="form-select"
          :options="statusOptions"
          :class="status($v.form.status)"
          type="text"
          @change="setFormValue('status', $event)"
        />
        <span v-if="!!errorMessages.form.status" class="label-error"
          >{{ errorMessages.form.status }}
        </span>
      </b-form-group>
      <!--    end layout modal-->
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { fileImageToBase64, validateFileSize, validateFileType } from '@/utils/function-utils'
import { DEFAULT_IMAGE, MAX_FILE_SIZE } from '@/utils/constants'
import { MESSAGES } from '@/utils/messages'

export default {
  name: 'ManagementServiceForm',
  props: {
    formData: {
      type: Object,
      default: null,
    },
    triggerValidate: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {},
      imageRef: 'image-label',
      defaultLogo: DEFAULT_IMAGE,
      errorMessages: {
        form: {
          name: '',
          category_id: '',
          status: '',
          logo: '',
        },
      },
      categoryOptions: [],
      statusOptions: [
        { value: 0, text: 'メンテナンス中' },
        { value: 1, text: '使用可能' },
      ],
      allowFileTypes: ['png', 'jpg', 'jpeg'],
    }
  },
  validations: {
    form: {
      name: {
        required,
      },
      category_id: {
        required,
      },
      status: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters('commonSettings', ['apps_category', 'mt_status']),
    defaultFormData() {
      return { name: '', logo: '', category_id: 1, status: 1 }
    },
  },
  watch: {
    form(newValue) {
      this.$emit('update:formData', newValue)
    },
    triggerValidate(newVal) {
      this.$emit('update:isValid', this.isValidForm())
    },
  },
  mounted() {
    this.categoryOptions = this.apps_category.map((item) => ({ value: item.id, text: item.name }))
    this.form = { ...this.defaultFormData }
    if (this.formData) {
      this.form = { ...this.form, ...this.formData }
    }
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0]
      this.errorMessages.form.logo = ''
      this.$refs['upload-image'].value = ''
      // validate file type
      if (!validateFileSize(file, MAX_FILE_SIZE.IMAGE)) {
        this.errorMessages.form.logo = MESSAGES.COMMON.MSG99
        return
      }
      // validate file size
      if (!validateFileType(file, this.allowFileTypes)) {
        this.errorMessages.form.logo = MESSAGES.COMMON.MSG23
        return
      }
      // validate file type
      fileImageToBase64(file).then((result) => {
        this.form.logo = result
      })
    },
    handleClickImage() {
      this.$refs[this.imageRef].click()
    },
    setValidateErrorMessage(instance, name) {
      // check required
      if (!instance.required) {
        this.errorMessages.form[name] = '必須項目です。'
      } else {
        this.errorMessages.form[name] = ''
      }
    },
    setFormValue(name, value) {
      if (value === undefined) {
        return
      }
      this.form[name] = value
      const validateInstance = this.$v.form[name]
      validateInstance.$touch()
      this.setValidateErrorMessage(validateInstance, name)
    },
    status(validation) {
      return {
        invalid: validation.$dirty && validation.$invalid,
      }
    },
    isValidForm() {
      this.$v.form.$touch()
      this.errorMessages.form.logo = ''
      const isValid = !this.$v.form.$invalid
      if (!isValid) {
        Object.keys(this.errorMessages.form).forEach((key) => {
          this.setValidateErrorMessage(this.$v.form[key], key)
        })
      }
      return isValid
    },
  },
}
</script>

<style lang="scss">
.MSForm {
  &__image {
    margin-bottom: 15px;
    div:first-of-type {
      display: flex;
      align-items: flex-end;
    }
    img {
      border-radius: 10px !important;
      width: 120px;
      height: 120px;
      &:hover {
        cursor: pointer;
        opacity: 0.5;
      }
    }
  }
  &__logo {
    &-label {
      margin-bottom: 0;
      cursor: pointer;
      &:hover {
        button {
          border-color: lightgray;
        }
      }
    }
    &-button {
      pointer-events: none;
      margin-left: 20px;
    }
    &-file {
      display: none;
    }
  }
}
</style>
